
// import Swiper JS
import Swiper from "./swiper-bundle.min.js";
// import Swiper styles
import '../../scss/swiper.scss';

function showNavAgain() {
    $('.loader').hide();

    $('.swiper-btn-prev').removeClass('d-none');
    $('.swiper-btn-next').removeClass('d-none');

    $('.swiper-btn-prev').removeClass('animate__animated animate__fadeOutLeft');
    animateCSS('.swiper-btn-prev', 'fadeInLeft');

    $('.swiper-btn-next').removeClass('animate__animated animate__fadeOutRight');
    animateCSS('.swiper-btn-next', 'fadeInRight');
}

$(function() {

    let $html = $('html'),
        $swiperContainer = '.swiper-container',
        $historyRoot = $('.swiper-container').data("history-root"),
        $historyKey = $('.swiper-container').data("history-key"),
        $a11yLabel = $('.swiper-container').data("label"),
        $nextPageLabel = $('.swiper-btn-next').data("label"),
        $prevPageLabel =  $('.swiper-btn-prev').data("label");

    (function () {
        const mySwiper = new Swiper($swiperContainer, {
            speed: 320,
            slidesPerView: 1,
            initialSlide: 1,
            longSwipesRatio: .05,
            longSwipesMs: 10,
            threshold: 19,
            preventInteractionOnTransition: true,
            navigation: {
                nextEl: '.swiper-btn-next',
                prevEl: '.swiper-btn-prev',
            },
            history: {
                key: $historyKey,
                replaceState: true,
                root: $historyRoot
            },
            keyboard: {
                enabled: true
            },
            a11y: {
                containerMessage: $a11yLabel,
                containerRoleDescriptionMessage: 'carousel',
                itemRoleDescriptionMessage: 'slide',
                nextSlideMessage: $nextPageLabel,
                prevSlideMessage: $prevPageLabel,
                slideLabelMessage: ''
            },
            on: {
                // Fill prev/next slider with ajax
                init: function () {
                    $html.addClass('swiper-initialized');

                    $('.swiper-slide').removeClass('invisible')

                    $('.swiper-slide-next').find('[role="button"]').attr("tabindex", -1);
                    $('.swiper-slide-next').find('button').attr("tabindex", -1);
                    $('.swiper-slide-next').find('a').attr("tabindex", -1);


                    $('.swiper-slide-prev').find('[role="button"]').attr("tabindex", -1);
                    $('.swiper-slide-prev').find('button').attr("tabindex", -1);
                    $('.swiper-slide-prev').find('a').attr("tabindex", -1);


                    $('.swiper-btn-prev').removeClass('init');
                    animateCSS('.swiper-btn-prev', 'fadeInLeft', '1-5s');

                    $('.swiper-btn-next').removeClass('init');
                    animateCSS('.swiper-btn-next', 'fadeInRight', '1-5s');

                    animateCSS('.swiper-slide-active .speaker__image img', 'rollIn');
                    animateCSS('.swiper-slide-active .speaker__content h1', 'fadeInLeft2', '1s');
                    animateCSS('.swiper-slide-active .speaker__content h2.text-center', 'fadeInLeft2', '1-5s');
                    animateCSS('.swiper-slide-active .speaker__content h3.text-center', 'fadeInLeft2', '1-5s');
                    animateCSS('.swiper-slide-active .speaker__content .social-list-icon', 'fadeIn', '2s');
                    animateCSS('.swiper-slide-active .speaker__quicklinks__links a', 'fadeIn', '2s');
                    animateCSS('.swiper-slide-active .speaker__content .speaker__content__txt', 'fadeIn', '2s');
                    animateCSS('.swiper-slide-active .speaker__content .speaker__content__orga', 'fadeIn', '2s');
                    animateCSS('.swiper-slide-active .speaker__content .speaker__content__activities', 'fadeIn', '2s');
                    animateCSS('.swiper-slide-active .speaker__content .speaker__content__sessions', 'fadeIn', '2s');

                    $('.swiper-slide-active .speaker__content .accordion .card.session').each(function (index, element) {
                        animateCSS('.swiper-slide-active .speaker__content .accordion .card.session:nth-of-type('+ (index+1) +')', 'zoomIn', (3+index) + 's', 'faster');
                    });

                    // Load next Page in next slider to update agenda
                    let $nextPageUrl = $('.swiper-btn-next').attr("data-url");
                    $.get($nextPageUrl, function (data) {
                        let $data = $(data),
                            $nextSlide = $data.find('.swiper-slide:nth-of-type(2)').children();
                        $(".swiper-slide:nth-of-type(3)").html($nextSlide).html();

                        // Reload Bootstrap tooltip
                        $('[data-toggle="tooltip"]').tooltip();

                        $('.swiper-slide-next').find('[role="button"]').attr("tabindex", -1);
                        $('.swiper-slide-next').find('button').attr("tabindex", -1);
                        $('.swiper-slide-next').find('a').attr("tabindex", -1);
                    });

                    // Load prev Page in prev slider to update agenda
                    let $prevPageUrl = $('.swiper-btn-prev').attr("data-url");
                    $.get($prevPageUrl, function (data) {
                        let $data = $(data),
                            $prevSlide = $data.find('.swiper-slide:nth-of-type(2)').children();
                        $(".swiper-slide:nth-of-type(1)").html($prevSlide).html();

                        // Reload Bootstrap tooltip
                        $('[data-toggle="tooltip"]').tooltip();

                        $('.swiper-slide-prev').find('[role="button"]').attr("tabindex", -1);
                        $('.swiper-slide-prev').find('button').attr("tabindex", -1);
                        $('.swiper-slide-prev').find('a').attr("tabindex", -1);
                    });

                },
                // After prev event = Add new prev slider, remove old next slider, update prev/next button
                slidePrevTransitionEnd: function () {

                    mySwiper.prependSlide('<div class="swiper-slide"></div>');
                    mySwiper.removeSlide(3);
                    mySwiper.update();

                    let $prevPageUrl = $('.swiper-btn-prev').attr("data-url");

                    getSlide("prev",$prevPageUrl);
                }
            }
        });

        // After next event = Add new next slider, remove old prev slider, update prev/next button
        mySwiper.on('slideNextTransitionEnd', function () {

            mySwiper.appendSlide('<div class="swiper-slide"></div>');
            mySwiper.removeSlide(0);
            mySwiper.update();

            let $nextPageUrl = $('.swiper-btn-next').attr("data-url");
            getSlide("next",$nextPageUrl);
        });

        // Transition slide for common event
        mySwiper.on('slideChangeTransitionStart', function () {
            animateCSS('.swiper-slide-active .speaker__quicklinks__links a', 'fadeIn', '2s');
            animateCSS('.swiper-slide-active .speaker__content .social-list-icon', 'fadeIn', '2s');
            animateCSS('.swiper-slide-active .speaker__content .speaker__content__txt', 'fadeIn', '2s');
            animateCSS('.swiper-slide-active .speaker__content .speaker__content__orga', 'fadeIn', '2s');
            animateCSS('.swiper-slide-active .speaker__content .speaker__content__activities', 'fadeIn', '2s');
            animateCSS('.swiper-slide-active .speaker__content .speaker__content__sessions', 'fadeIn', '2s');

            $('.swiper-slide-active .speaker__content .accordion .card.session').each(function (index, element) {
                animateCSS('.swiper-slide-active .speaker__content .accordion .card.session:nth-of-type('+ (index+1) +')', 'zoomIn', (3+index) + 's', 'faster');
            });

            $('.swiper-btn-prev').addClass('d-none');
            $('.swiper-btn-next').addClass('d-none');

            $('.loader').show();

            $('.swiper-btn-prev').blur();
            $('.swiper-btn-next').blur();

            $('.swiper-container').addClass('swiper-no-swiping');
        });
        mySwiper.on('slideChangeTransitionEnd', function () {
            $('.swiper-slide-active').find('[role="button"]').attr("tabindex", 0);
            $('.swiper-slide-active').find('button').removeAttr("tabindex");
            $('.swiper-slide-active').find('a').removeAttr("tabindex");

            $('.swiper-slide-prev').find('[role="button"]').attr("tabindex", -1);
            $('.swiper-slide-prev').find('button').attr("tabindex", -1);
            $('.swiper-slide-prev').find('a').attr("tabindex", -1);

            $('.swiper-slide-next').find('[role="button"]').attr("tabindex", -1);
            $('.swiper-slide-next').find('button').attr("tabindex", -1);
            $('.swiper-slide-next').find('a').attr("tabindex", -1);

            setTimeout(function() {
                $('.swiper-container').removeClass('swiper-no-swiping');
            }, 500);
        });

        // Transition slide for prev event
        mySwiper.on('slidePrevTransitionStart', function () {
            animateCSS('.swiper-slide-active .speaker__image img', 'rollIn');
            animateCSS('.swiper-slide-active .speaker__content h1', 'fadeInLeft2', '1s');
            animateCSS('.swiper-slide-active .speaker__content h2.text-center', 'fadeInLeft2', '1-5s');
            animateCSS('.swiper-slide-active .speaker__content h3.text-center', 'fadeInLeft2', '1-5s');
        });

        // Transition slide for next event
        mySwiper.on('slideNextTransitionStart', function () {
            animateCSS('.swiper-slide-active .speaker__image img', 'rollInRight');
            animateCSS('.swiper-slide-active .speaker__content h1', 'fadeInRight2', '1s');
            animateCSS('.swiper-slide-active .speaker__content h2.text-center', 'fadeInRight2', '1-5s');
            animateCSS('.swiper-slide-active .speaker__content h3.text-center', 'fadeInRight2', '1-5s');
        });

        // Keyboard for prev/next slide
        if($($swiperContainer).length > 0) {
            document.addEventListener("keydown", function(e){
                if(e.keyCode == 37) {
                    mySwiper.slidePrev();
                    //Left arrow pressed
                }
                if(e.keyCode == 39) {
                    mySwiper.slideNext();
                    //Right arrow pressed
                }
            });
        }

        // Get prev/next slide
        function getSlide(slide, url) {
            if (slide == "prev") {
                $.get(url, function (data) {
                    let $data = $(data),
                        $prevSlide = $data.find('.swiper-slide:nth-of-type(1)').children(),
                        $prevHistory = $data.find('.swiper-slide:nth-of-type(1)').data("history"),
                        $prevSlideImg = $data.find('.swiper-btn-prev img').attr("src"),
                        $prevSlideSrcSet = $data.find('.swiper-btn-prev picture source').attr("srcset"),
                        $prevSlideUrl = $data.find('.swiper-btn-prev').data("url"),
                        $prevSlideTitle = $data.find('.swiper-btn-prev').attr("title"),
                        $nextSlideImg = $data.find('.swiper-btn-next img').attr("src"),
                        $nextSlideSrcSet = $data.find('.swiper-btn-next picture source').attr("srcset"),
                        $nextSlideUrl = $data.find('.swiper-btn-next').data("url"),
                        $nextSlideTitle = $data.find('.swiper-btn-next').attr("title");


                    $(".swiper-slide-prev").html($prevSlide).html();
                    $('.swiper-slide-prev').attr('data-history', $prevHistory);
                    $(".swiper-btn-next img").attr("src", $nextSlideImg);
                    $(".swiper-btn-next picture source").attr("srcset", $nextSlideSrcSet);
                    $('.swiper-btn-next').attr('data-url', $nextSlideUrl);
                    $('.swiper-btn-next').attr('data-original-title', $nextSlideTitle);
                    $(".swiper-btn-prev img").attr("src", $prevSlideImg);
                    $(".swiper-btn-prev picture source").attr("srcset", $prevSlideSrcSet);
                    $('.swiper-btn-prev').attr('data-url', $prevSlideUrl);
                    $('.swiper-btn-prev').attr('data-original-title', $prevSlideTitle);

                    $prevSlide.find('[role="button"]').attr("tabindex", -1);
                    $prevSlide.find('button').attr("tabindex", -1);
                    $prevSlide.find('a').attr("tabindex", -1);

                    // Load prev Page in prev slider to update agenda
                    let $prevPageUrl = $('.swiper-btn-prev').attr("data-url");
                    $.get($prevPageUrl, function (data) {
                        let $data = $(data),
                            $prevSlide = $data.find('.swiper-slide:nth-of-type(2)').children();
                        $(".swiper-slide:nth-of-type(1)").html($prevSlide).html();

                        // Reload Bootstrap tooltip
                        $('[data-toggle="tooltip"]').tooltip();

                        $prevSlide.find('[role="button"]').attr("tabindex", -1);
                        $prevSlide.find('button').attr("tabindex", -1);
                        $prevSlide.find('a').attr("tabindex", -1);
                    });

                    showNavAgain();

                });
            } else {
                $.get(url, function (data) {
                    let $data = $(data),
                        $nextSlide = $data.find('.swiper-slide:nth-of-type(3)').children(),
                        $nextHistory = $data.find('.swiper-slide:nth-of-type(3)').data("history"),
                        $prevSlideImg = $data.find('.swiper-btn-prev img').attr("src"),
                        $prevSlideSrcSet = $data.find('.swiper-btn-prev picture source').attr("srcset"),
                        $prevSlideUrl = $data.find('.swiper-btn-prev').data("url"),
                        $prevSlideTitle = $data.find('.swiper-btn-prev').attr("title"),
                        $nextSlideImg = $data.find('.swiper-btn-next img').attr("src"),
                        $nextSlideSrcSet = $data.find('.swiper-btn-next picture source').attr("srcset"),
                        $nextSlideUrl = $data.find('.swiper-btn-next').data("url"),
                        $nextSlideTitle = $data.find('.swiper-btn-next').attr("title");
                    $(".swiper-slide-next").html($nextSlide).html();
                    $('.swiper-slide-next').attr('data-history', $nextHistory);
                    $(".swiper-btn-next img").attr("src", $nextSlideImg);
                    $(".swiper-btn-next picture source").attr("srcset", $nextSlideSrcSet);
                    $('.swiper-btn-next').attr('data-url', $nextSlideUrl);
                    $('.swiper-btn-next').attr('data-original-title', $nextSlideTitle);
                    $(".swiper-btn-prev img").attr("src", $prevSlideImg);
                    $(".swiper-btn-prev picture source").attr("srcset", $prevSlideSrcSet);
                    $('.swiper-btn-prev').attr('data-url', $prevSlideUrl);
                    $('.swiper-btn-prev').attr('data-original-title', $prevSlideTitle);

                    $nextSlide.find('[role="button"]').attr("tabindex", -1);
                    $nextSlide.find('button').attr("tabindex", -1);
                    $nextSlide.find('a').attr("tabindex", -1);

                    // Load next Page in next slider to update agenda
                    let $nextPageUrl = $('.swiper-btn-next').attr("data-url");
                    $.get($nextPageUrl, function (data) {
                        let $data = $(data),
                            $nextSlide = $data.find('.swiper-slide:nth-of-type(2)').children();
                        $(".swiper-slide:nth-of-type(3)").html($nextSlide).html();

                        // Reload Bootstrap tooltip
                        $('[data-toggle="tooltip"]').tooltip();

                        $nextSlide.find('[role="button"]').attr("tabindex", -1);
                        $nextSlide.find('button').attr("tabindex", -1);
                        $nextSlide.find('a').attr("tabindex", -1);
                    });

                    showNavAgain();

                });
            }



        }

    })();

});



// Animate CSS
const animateCSS = (element, animation, delay, duration, prefix = 'animate__') =>
// We create a Promise and return it
new Promise((resolve, reject) => {
const animationName = `${prefix}${animation}`;
const animationDelay = `${prefix}`+ 'delay-' +`${delay}`;
const animationDuration = `${prefix}${duration}`;
const node = document.querySelectorAll(element);

for (var i = 0; i < node.length; i++) {
    node[i].classList.add(`${prefix}animated`, animationName, animationDelay, animationDuration);
}

// When the animation ends, we clean the classes and resolve the Promise
function handleAnimationEnd(event) {
    event.stopPropagation();
    for (var i = 0; i < node.length; i++) {
        node[i].classList.remove(`${prefix}animated`, animationName, animationDelay, animationDuration);
    }
    resolve('Animation ended');
}

for (var i = 0; i < node.length; i++) {
    node[i].addEventListener('animationend', handleAnimationEnd, {once: true});
    }



});